import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import { TopicTopper, ArticleIndex, SEO, CTA } from 'components';

const Topic = ({ data: { topic, allTopics, articles }, location: { pathname }, pageContext }) => {
  const { seo, cta, hideFooterCta, name, slug } = topic;
  const { numPages, currentPage, basePath } = pageContext;
  const topics = allTopics.edges.map(({ node }) => node);
  const mappedArticles = articles.edges.map(({ node }) => node);

  const shareImage = get(topic, 'image');

  return (
    <div>
      <SEO title={name} shareImage={shareImage} {...seo} pathname={pathname} />
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <TopicTopper headline={name} />
      <ArticleIndex
        articles={mappedArticles}
        basePath={`${basePath}/${slug}`}
        currentPage={currentPage}
        numPages={numPages}
        topics={topics}
      />
      {!hideFooterCta && <CTA {...cta} onFooter />}
    </div>
  );
};

Topic.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query topicQuery($skip: Int!, $limit: Int!, $slug: String!) {
    topic: contentfulTopic(slug: { eq: $slug }) {
      slug
      name
      image {
        title
        file {
          url
        }
      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
    }

    allTopics: allContentfulTopic(sort: { fields: name }) {
      edges {
        node {
          name
          slug
        }
      }
    }

    articles: allContentfulArticle(
      filter: { topics: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          author {
            name
            image {
              description
              title
              file {
                url
              }
            }
          }
          slug
          title
          intro {
            intro
          }
          date(formatString: "MM.DD.YY")
          articleImage {
            description
            title
            file {
              url
            }
          }
          featuredImage {
            title
            description
            file {
              url
            }
          }
          topics {
            name
            slug
            image {
              title
              description
              file {
                url
              }
            }
          }
          content {
            json
          }
        }
      }
    }
  }
`;

export default Topic;
